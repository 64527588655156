import axios from '@/utils/axios';
const getAll = async (cod_empresa) => {
    try {
        // console.log(cod_empresa, { empresa_id: cod_empresa });

        return (await axios.get('impressora/getall', { params: { empresa_id: cod_empresa } })).data;
    } catch {
        return null;
    }
}
const salvar = async (impressora) => {
    return (await axios.post('impressora/store', impressora)).data;
}

export default {
    getAll,
    salvar
}