<template>
  <div class="container">
    <BarraCrud />
    <div class="row barraTopoCrud">
      <div class="col-12">
        <empresa-combo :selecionar="changeEmpresa" :noStyle="false" />
      </div>
    </div>

    <div class="row">
      <div class="col-12 text-right">
        <button class="btn btn-default" @click="novo">
          <i class="fa fa-plus"></i>
          Nova Impressora
        </button>
      </div>
      <div v-if="loading" class="col-12">
        <b-spinner grow small variant="primary" />
      </div>
      <div
        class="col-12 col-sm-6 col-lg-4"
        v-for="imp in lista"
        :key="imp.cod_impressora"
      >
        <div class="boxImpressora text-left">
          <div class="logo">
            <div class="linha">
              <i class="fa fa-print"></i>
              <div v-if="imp.edit" class="inpt-box">
                <label>Nome de Impressora</label>
                <b-input v-model="imp.nome" v-if="imp.edit" />
              </div>
              <div v-else class="titulo">
                {{ imp.nome }}
              </div>
              <div
                class="text-right"
                style="flex-grow:1; align-self:center; font-size:10px"
              >
                <b-check
                  switch
                  v-model="imp.status"
                  @change="habilitaEdicao(imp)"
                ></b-check>
              </div>
            </div>
          </div>
          <div class="linha">
            <div class="icon">
              <i class="fa fa-tv"></i>
            </div>
            <div v-if="imp.edit" class="inpt-box">
              <label>Computador</label>
              <input v-model="imp.computador" />
            </div>
            <span v-if="!imp.edit">{{ imp.computador }}</span>
          </div>
          <div class="linha">
            <div class="icon">
              <i class="fa fa-location-arrow"></i>
            </div>
            <span v-if="!imp.edit">{{ imp.caminho }}</span>
            <div v-if="imp.edit" class="inpt-box">
              <label>Caminho Impressora</label>
              <input v-model="imp.caminho" />
            </div>
          </div>
          <div class="linha">
            <div class="icon">
              <i class="fa fa-cash-register"></i>
            </div>
            <span v-if="!imp.edit">{{
              $store.state.caixas.find(x => x.cod_caixa == imp.cod_caixa)
                ? $store.state.caixas.find(x => x.cod_caixa == imp.cod_caixa)
                    .nome
                : ""
            }}</span>
            <div v-if="imp.edit" class="inpt-box">
              <label>Caixa</label>
              <caixa-combo
                :caixa="imp.cod_caixa"
                @ch="changeCaixa(imp, $event)"
                :CaixasLista="caixas"
              />
            </div>
          </div>
          <div class="linha d-flex" style="flex-flow:row wrap; font-size:12px">
            <b-check
              switch
              v-model="imp.tipo_impressao.senhas"
              @change="habilitaEdicao(imp)"
              >Senhas</b-check
            >
            <b-check
              switch
              v-model="imp.tipo_impressao.pizza_salgada"
              @change="habilitaEdicao(imp)"
              >Pizza Salgada</b-check
            >
            <b-check
              switch
              v-model="imp.tipo_impressao.pizza_doce"
              @change="habilitaEdicao(imp)"
              >Pizza Doce</b-check
            >
            <b-check
              switch
              v-model="imp.tipo_impressao.bebidas"
              @change="habilitaEdicao(imp)"
              >Bebidas</b-check
            >
            <b-check
              switch
              v-model="imp.tipo_impressao.online"
              @change="habilitaEdicao(imp)"
              >Online</b-check
            >
            <b-check
              switch
              v-model="imp.tipo_impressao.caixas"
              @change="habilitaEdicao(imp)"
              >Caixas</b-check
            >
            <b-check
              switch
              v-model="imp.tipo_impressao.sushi"
              @change="habilitaEdicao(imp)"
              >SUSHI</b-check
            >

            <b-check
              switch
              v-model="imp.tipo_impressao.mesas_pedido"
              @change="habilitaEdicao(imp)"
              >Mesas Pedido</b-check
            >

            <b-check
              switch
              v-model="imp.tipo_impressao.mesas_bebidas"
              @change="habilitaEdicao(imp)"
              >Mesas Bebidas</b-check
            >

            <b-check
              switch
              v-model="imp.tipo_impressao.mesas_conta"
              @change="habilitaEdicao(imp)"
              >Mesa Conta</b-check
            >

            <b-check
              switch
              v-model="imp.tipo_impressao.fechamento_caixa"
              @change="habilitaEdicao(imp)"
              >Fechamento de Caixa</b-check
            >

                 <b-check
              switch
              v-model="imp.tipo_impressao.esfihas"
              @change="habilitaEdicao(imp)"
              >Fechamento de Caixa</b-check
            >
          </div>
          <div class="block text-center" v-if="imp.loading">
            <b-spinner small grow /> aguarde...
          </div>
          <div
            class="btn btn-block block"
            v-if="imp.edit"
            style="font-size: large"
            @click="salvar(imp)"
          >
            <i class="fa fa-save"></i>
            Salvar
          </div>
          <div
            class="btn  btn-block block"
            style="font-size: large"
            v-if="!imp.edit"
            @click="habilitaEdicao(imp)"
          >
            <i class="fa fa-edit"></i>
            Editar
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EmpresaCombo from "../../components/empresa/EmpresaCombo.vue";
import "@/assets/scss/crud.scss";
import ImpressoraLib from "@/libs/Impressoras";
import CaixaCombo from "../../components/empresa/CaixaCombo.vue";
import axios from "@/utils/axios.js";
import BarraCrud from "@/components/common/BarraCrud.vue";
import PrinterWindowHelper from "../../utils/PrinterWindowHelper";

export default {
  components: { EmpresaCombo, CaixaCombo, BarraCrud },
  data() {
    return {
      lista: [],
      loading: true,
      empresa: null,
      caixas: []
    };
  },
  mounted() {
    this.carregaCaixas();
  },
  methods: {
    print() {
      PrinterWindowHelper(document.getElementById("print").innerHTML);
    },
    async carregaCaixas() {
      this.caixas = (
        await axios.get("/caixas", {
          params: { empresa_id: this.empresa.cod_empresa }
        })
      ).data;
      // console.log("caixas", this.caixas);
    },
    habilitaEdicao(imp) {
      imp.edit = true;
      this.$forceUpdate();
    },
    async salvar(imp) {
      imp.loading = true;
      this.$forceUpdate();
      // console.log("imp---------------", imp);
      const res = await ImpressoraLib.salvar(imp);
      // console.log("rets", res);
      if (res) {
        imp.loading = false;
        imp.edit = false;
        this.$forceUpdate();
      }
      this.carregar();
    },
    changeCaixa(imp, caixa) {
      imp.cod_caixa = caixa;
    },
    changeEmpresa(emp) {
      this.empresa = emp;
      this.carregar();
    },
    async carregar() {
      this.loading = true;
      this.carregaCaixas();
      this.lista = [];
      try {
        if (this.empresa) {
          // console.log("emp", this.empresa.cod_empresa);
          this.lista = await ImpressoraLib.getAll(this.empresa.cod_empresa);
          if (!this.lista) {
            this.lista = [];
          }
          if (this.lista && this.lista.length > 0) {
            this.lista.map(item => {
              item.status = item.ativo == 1;
              if (!item.tipo_impressao) {
                item.tipo_impressao = {
                  senhas: false,
                  pizza_salgada: false,
                  pizza_doce: false,
                  bebidas: false,
                  online: false,
                  caixas: false,
                  sushi: false,
                  mesas_pedido: false,
                  mesas_bebidas: false,
                  mesas_conta: false,
                  fechamento_caixa: false,
                  esfihas:false,
                };
              }
            });
          }
          this.loading = false;

          // console.log("imps", this.lista);
        }
      } catch {
        this.lista = [];
      }
    },
    novo() {
      this.lista.push({
        cod_impressora: 0,
        edit: true,
        nome: "",
        computador: "",
        caminho: "",
        status: true,
        cod_caixa: -1,
        cod_empresa: this.empresa.cod_empresa,
        tipo_impressao: {
          senhas: false,
          pizza_salgada: false,
          pizza_doce: false,
          bebidas: false,
          online: false,
          caixas: false,
          sushi: false,
          mesas_pedido: false,
          mesas_bebidas: false,
          mesas_conta: false,
          esfihas: false
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
input,
select {
  width: auto;
  background-color: white !important;
  margin-left: 10px;
  padding: 4px;
}
input:hover,
select:hover {
  background-color: #f9f9f9 !important;
}
.boxImpressora {
  margin-top: 10px;
  background-color: #f0f0f0;
  min-height: 200px;
  .titulo {
    font-size: 1rem;
    padding: 10px;
  }
  .linha {
    width: 100%;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    flex-direction: row;
    .custom-switch {
      display: block;
      width: 100%;
    }
    .inpt-box {
      padding-left: 10px;
      flex-grow: 1;
      label {
        font-size: 11px;
        margin-bottom: 0px;
        padding-left: 2px;
        font-weight: bold;
        display: block;
      }
      input {
        margin: 0px;
        width: 80%;
        font-size: 10px;
      }
    }
    .icon {
      width: 30px;
      height: 100%;
      text-align: center;
      align-self: center;
    }
  }
  .logo {
    font-size: 2rem;
    background-color: white;
    text-align: left;
    padding-left: 5px;
    display: flex;
    padding-bottom: 5px;
    margin: 0px;
  }
}
</style>